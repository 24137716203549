<template>
  <v-app-bar
    id="app-bar"
    color="transparent"
    :height="getHeight"
    absolute
    app
    flat
  >
    <v-progress-linear
      :active="$loading()"
      :indeterminate="$loading()"
      color="primary accent-4"
      absolute
      top
    />
    <v-btn
      v-if="$vuetify.breakpoint.mdAndDown"
      class="mr-3 mt-md-8"
      icon
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon> $menu </v-icon>
    </v-btn>
    <component :is="appBarContent" v-show="getStatsLoaded" />
  </v-app-bar>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import AppBarDefault from "./AppBarViews/AppBarDefault";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "DashboardCoreAppBar",
  mixins: [sharedActions],
  computed: {
    ...mapGetters({
      drawer: "getDrawer",
    }),
    getHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 200;
      }

      return 220;
    },
    appBarContent() {
      const route = this.$route.name;
      const list = [
        {
          route: "dashboard",
          component: () => import("./AppBarViews/AppBarDashboard.vue"),
        },
        {
          route: "calendar",
          component: () =>
            import(
              "@/views/dashboard/pages/Calendar/components/CalendarHeader"
            ),
        },
        {
          route: "sales",
          component: () =>
            import("@/views/dashboard/pages/Sales/AppBarSales.vue"),
        },
        {
          route: "offer",
          component: () =>
            import("@/views/dashboard/pages/Offer/AppBarOffer.vue"),
        },
        {
          route: "service",
          component: () =>
            import("@/views/dashboard/pages/Offer/AppBar/AppBarService.vue"),
        },
        {
          route: "add_service",
          component: () => import("./AppBarViews/AppBarAddNew"),
        },
        {
          route: "users",
          component: () => import("./AppBarViews/AppBarList"),
        },
        {
          route: "user",
          component: () => import("./AppBarViews/UserAppBar"),
        },
        {
          route: "add_user",
          component: () => import("./AppBarViews/AppBarAddNew"),
        },
        {
          route: "employees",
          component: () => import("./AppBarViews/AppBarList"),
        },
        {
          route: "employee",
          component: () => import("./AppBarViews/EmployeeAppBar"),
        },
        {
          route: "add_employee",
          component: () => import("./AppBarViews/AppBarAddNew"),
        },
        {
          route: "availability",
          component: () => import("./AppBarViews/AppBarAvailability"),
        },
        {
          route: "tools",
          component: () => import("@/views/dashboard/pages/Tools/AppBarTools"),
        },
        {
          route: "settings",
          component: () =>
            import("@/views/dashboard/pages/Settings/AppBarSettings"),
        },
        {
          route: "integrations",
          component: () => import("./AppBarViews/AppBarSimple"),
        },
        {
          route: "notifications",
          component: () =>
            import("@/views/dashboard/pages/Notifications/AppBarNotifications"),
        },
        {
          route: "billing",
          component: () => import("./AppBarViews/AppBarSimple"),
        },
      ];
      const element = list.find((x) => x.route === route);
      return element ? element.component : AppBarDefault;
    },
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
  },
};
</script>
